.footer {
  background: var(--color-main) url("../../assets/images/ft-bg.png") center no-repeat;
  background-size: cover;
  padding: 60px 0 40px;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    padding: 40px 0;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    border-bottom: 1px solid #31587B;

    .bl {
      width: 50%;

      @media (max-width: 1023px) {
        width: 100%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.blLeft {

        .slogan {
          font-size: 14px;
          color: white;
        }
      }

      .ft-menu {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .column {
          width: calc(33% - 40px);

          @media (max-width: 1023px) {
            width: 100%;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .label {
          font-size: 14px;
          color: white;
          font-weight: 800;
          display: block;
          padding-bottom: 10px;
          position: relative;
          margin-bottom: 20px;

          @media (max-width: 1023px) {
            width: 60%;
            margin-bottom: 16px;
          }

          &::before {
            width: 100%;
            height: 2px;
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background-color: #d6d6d6;
          }

          &::after {
            width: 50%;
            height: 2px;
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background-color: var(--color-primary);
          }
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style-type: none;

            a {
              padding: 10px 0;
              color: white;
              font-size: 14px;
              display: block;
              text-decoration: none;
              transition: all ease 0.5s;
              font-weight: 500;
              p {
                margin: 0;
              }
              @media (max-width: 1023px) {
                padding: 8px 0;
              }

              &:hover,
              &.active {
                color: var(--color-primary);
              }

              .highlight {
                color: var(--color-primary);
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    flex-wrap: wrap;

    .copy-right {
      color: #BDBDBD;
      font-size: 14px;

      @media (max-width: 1023px) {
        text-align: center;
        margin-bottom: 16px;
        width: 100%;
      }
    }

    .social {
      @media(max-width: 1023px) {
        width: 100%;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;

        @media(max-width: 1023px) {
          width: 100%;
          justify-content: center;
        }

        li {
          list-style-type: none;
          margin-right: 30px;

          @media(max-width: 1023px) {
            margin: 0 10px;
          }

          .item {
            .ic {
              width: 16px;
              margin-right: 10px;
              position: relative;

              &:hover,
              &:focus {
                &::before {
                  opacity: 1;
                  transform: translate(-50%, -50%) scale(1);
                }
              }

              &::before {
                display: block;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0.5);
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                border-radius: 5px;
                border: 0.5px solid var(--color-secondary);
                opacity: 0;
                transition: all ease 0.5s;
              }
            }
          }
        }
      }
    }
  }
}